<template>
  <v-container class="mt-3 mb-5">
    <v-data-table
      :show-expand="!$appConfig.widget"
      :single-expand="!$appConfig.widget"
      :hide-default-footer="$appConfig.widget"
      :headers="visibleHeaders"
      :items="items"
      @item-expanded="loadEvents"
    >
      <template v-slot:top>
        <v-toolbar
          v-if="!$appConfig.widget"
          flat
          class="my-2 py-2"
        >
          <v-toolbar-title class="align-self-start text-h5">
            <translate>Found %{ customersFound } caller(s)</translate>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-select
            v-model="selectedHeaders"
            :items="headerChoices"
            id="cv_selected_headers"
            single-line
            multiple
            dense
            outlined
            :hint="$gettext('Select which columns to hide/show')"
            persistent-hint
            class="narrow-select"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index <= 1">
                {{ item.text }}
              </v-chip>
              <span
                v-if="index === 2"
                class="grey--text caption"
              >
                (+{{ selectedHeaders.length - 2 }} others)
              </span>
            </template>
          </v-select>
        </v-toolbar>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <tr class="v-data-table__expanded v-data-table__expanded__content">
          <td
            v-if="eventsLoading"
            :colspan="headers.length"
            class="py-3"
          >
            <v-progress-linear
              indeterminate
              color="primary"
            ></v-progress-linear>
          </td>
          <td
            v-if="!eventsLoading"
            :colspan="headers.length"
            class="py-3"
          >
            <!--
              HACK ALERT: Why do we have have these nested <span> tags?
              There seems to be a bug in vue-gettext when it comes to updating
              elements in a expandable data-table, if we close the expansion and
              then open a different row's expansion the entry is updated with the
              correct translation, however if we do that in 1 click (e.g. open one
              row, then open another row which closes the previous row), then the
              msgid value that is passed to the update() call for the translation
              is actually the msgid for the row tha that is closing, so the wrong
              msgid is used and displayed.

              This seems to only impact identical element types (e.g. span) at the
              same level in the DOM hierarchy.  So either using different elements
              (div + span) or nesting span elements works around this and display
              the proper message.

              Another note, this error does not happen if the data hasn't been loaded
              yet so its possible this is a race condition in the vue + vue-gettext
              event model. We should review this if we upgrade/change things as it
              could be fixed or possibly break again.
            -->
            <v-container class="ma-0 pa-0">
              <v-row class="ma-0 pa-0">
                <v-col class="ma-0 pa-0">
                  <span
                    :id="'cv_failed_' + item.id"
                    v-if="item.events.length == 1"
                    v-translate='{ logHours: settings.logDeltaHours }'
                  >
                    failed-verification-events-single %{ logHours }
                  </span>
                  <span><span
                      :id="'cv_failed_' + item.id"
                      v-if="item.events.length > 1"
                      v-translate='{ numEvents: item.events.length, logHours: settings.logDeltaHours }'
                    >
                      failed-verification-events-multiple %{ numEvents } %{ logHours }
                    </span></span>
                  <span><span><span
                        :id="'cv_failed_' + item.id"
                        v-if="item.events.length == 0"
                        v-translate='{ logHours: settings.logDeltaHours }'
                      >
                        failed-verification-events-none %{ logHours }
                      </span></span></span>
                </v-col>
                <v-col class="ma-0 pa-0 text-right">
                  <v-btn
                    v-if="settings.featureFlags.enableLogDetails"
                    small
                    color="primary"
                    :id="'log_details_' + item.id"
                    @click="openActionDialog('log-details', item)"
                  >
                    <translate>View Detailed Logs</translate>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </td>
        </tr>

      </template>
      <!-- we should make the full name returned from our API call do avoid this construction -->
      <template v-slot:item="{ item, isExpanded, expand }">
        <tr
          :class="{ 'v-data-table__expanded v-data-table__expanded__row': isExpanded }"
          :id="'cid_' + item.id"
        >
          <td :id="'cname_' + item.id">{{ item.profile.firstName }} {{ item.profile.lastName }}</td>

          <td
            :id="'c' + header.value + '_' + item.id"
            v-for="(header, index) in selectableHeaders"
            :key="index"
          >
            {{ getItemProperty(item, header) }}
          </td>
          <td :id="'cstatus_' + item.id">
            <v-chip
              label
              :color="getStatusColor(item.status)"
            >
              <div v-translate='{ customerStatus: $gettext(item.status) }'>%{ customerStatus }</div>
            </v-chip>
          </td>
          <td :id="'cmfa_' + item.id">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-chip
                  label
                  :color="getStatusColor(item.mfa.status)"
                  v-on="on"
                  :class="{ loaded: item.mfa.status !== 'LOADING' }"
                >
                  <!-- lookup translated MFA factors status -->
                  {{ getMFAStatusDisplay(item.mfa.status) }}
                  <v-progress-circular
                    v-if="item.mfa.status === 'LOADING'"
                    color="white"
                    size="20"
                    indeterminate
                    class="ml-2"
                  >
                  </v-progress-circular>
                </v-chip>
              </template>
              <!-- we have to translate the LOADING tooltip here since we didn't have a chance to translate
                   it when the API call was made unlike all of the other factors, also we can't simply translate
                   all of the tooltip here, since we construct this of available factors that need when they are
                   loaded and they need to be translated individually when they are concatenated -->
              <span v-if="item.mfa.status === 'LOADING'">
                <translate>{{ item.mfa.tooltip }}</translate>
              </span>
              <span v-else>{{ item.mfa.tooltip }}</span>
            </v-tooltip>
          </td>
          <td v-if="settings.permission === 'read-write'">
            <div class="d-flex flex-nowrap">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    color="primary"
                    v-on="on"
                    :id="'action_' + item.id"
                  >
                    <translate>Action</translate>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    :id="'verify_' + item.id"
                    v-if="['ENROLLED', 'PENDING'].includes(item.mfa.status) && ['ACTIVE', 'LOCKED_OUT', 'PASSWORD_EXPIRED', 'RECOVERY'].includes(item.status)"
                    @click="openActionDialog('mfa-verify', item)"
                  >
                    <div>
                      <translate>Verify Caller</translate>
                    </div>
                  </v-list-item>
                  <v-list-item
                    :id="'resetpwd_' + item.id"
                    v-if="!settings.featureFlags.disablePasswordReset && ['ACTIVE', 'PASSWORD_EXPIRED', 'RECOVERY'].includes(item.status)"
                    @click="openActionDialog('reset-password', item)"
                  >
                    <div>
                      <div>
                        <translate>Reset Password</translate>
                      </div>
                    </div>
                  </v-list-item>
                  <v-list-item
                    :id="'manage_' + item.id"
                    v-if="canManageFactors && item.status === 'ACTIVE' &&
                      (item.mfa.status === 'ENROLLED' || (Object.keys(item.mfa.factors).length > 0))"
                    @click="openActionDialog('mfa-manage', item)"
                  >
                    <!-- HACK: extra div tag(s) here to avoid the translation issue, need to investigate this more -->
                    <div>
                      <translate>Manage Factors</translate>
                    </div>
                  </v-list-item>
                  <v-list-item
                    :id="'unlock_' + item.id"
                    v-if="!settings.featureFlags.disableUnlock && item.status === 'LOCKED_OUT'"
                    @click="openActionDialog('unlock', item)"
                  >
                    <!-- HACK: extra div tag(s) here to avoid the translation issue, need to investigate this more -->
                    <div>
                      <div>
                        <div>
                          <translate>Unlock</translate>
                        </div>
                      </div>
                    </div>
                  </v-list-item>
                  <v-list-item
                    :id="'profile_' + item.id"
                    class="text-decoration-none dropdown-item"
                    target="_blank"
                    :href="settings.oktaOrgUri + '/home/admin-entry?fromURI=/admin/user/profile/view/' + item.id + '#tab-account'"
                  >
                    <v-icon
                      color="secondary"
                      class="mr-1"
                    >mdi-launch</v-icon>
                    <div>
                      <div>
                        <div>
                          <div>
                            <translate>View Profile in Okta</translate>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <v-slide-x-transition mode="out-in">
                      <v-btn
                        :id="'qv_' + item.id"
                        v-if="item.mfa.status !== 'LOADING' && isQuickVerify(item)"
                        small
                        color="success"
                        min-width="34px"
                        class="px-0"
                        @click="quickVerifyFactorId = undefined; quickVerify(item)"
                      >
                        <QuickVerifyIcon />
                      </v-btn>
                    </v-slide-x-transition>
                  </span>
                </template>
                <div class="text-center">
                  <translate>This user qualifies for Quick Verify using:</translate>
                  <br />&nbsp;
                  <translate>{{ getQuickVerifyFactorName(item.mfa.factors) }}</translate>
                </div>
              </v-tooltip>
            </div>
          </td>
          <td
            v-if=" !$appConfig.widget "
            :id=" 'expand_' + item.id "
          >
            <v-tooltip top>
              <template v-slot:activator=" { on, attrs } ">
                <v-icon
                  v-on=" on "
                  v-bind=" attrs "
                  :class=" { expanded: isExpanded } "
                  @click=" expand(!isExpanded) "
                >mdi-chevron-down</v-icon>
              </template>
              <span v-translate=' { logHours: settings.logDeltaHours } '>Show last %{ logHours } hours failures.</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
    </v-data-table>
    <ResetPassword
      v-model=" dialogReset "
      :profile=" selectedProfile "
      :featureFlags=" settings.featureFlags "
      @close=" closeActionDialog "
      @notify=" $emit('showNotification', $event) "
      @updateStatus=' updateStatus '
    />
    <UnlockUser
      v-model=" dialogUnlock "
      :profile=" selectedProfile "
      @close=" closeActionDialog "
      @notify=" $emit('showNotification', $event) "
      @updateStatus=' updateStatus '
    />
    <VerifyUser
      v-model=" dialogVerify "
      :profile=" selectedProfile "
      :quickVerifyFactorId=" quickVerifyFactorId "
      :featureFlags=" settings.featureFlags "
      @close=" closeActionDialog "
      @qv_reset=" quickVerifyFactorId = null "
      @notify=" $emit('showNotification', $event) "
      @verifySuccess=" verifySuccess "
    />
    <ManageMFA
      v-model=" dialogManageMFA "
      :profile=" selectedProfile "
      :featureFlags=" settings.featureFlags "
      :idpDetails=" settings.idp "
      @close=" closeActionDialog "
      @notify=" $emit('showNotification', $event) "
      @reloadFactors=" $emit('reloadFactors', $event) "
    />
    <CallerLogDetails
      v-model=" dialogLogDetails "
      :profile=" selectedProfile "
      @close=" closeActionDialog "
      @notify=" $emit('showNotification', $event) "
    />


  </v-container>
</template>

<script>

import { translate } from 'vue-gettext';

import QuickVerifyIcon from '@/components/QuickVerifyIcon';
const { gettext: $gettext } = translate;
import cloneDeep from 'lodash/cloneDeep';

import ManageMFA from '@/components/ActionDialogs/ManageMFA'
import ResetPassword from '@/components/ActionDialogs/ResetPassword';
import UnlockUser from '@/components/ActionDialogs/UnlockUser';
import VerifyUser from '@/components/ActionDialogs/VerifyUser';
import CallerLogDetails from '@/components/CallerLogDetails';

export default {
  name: 'CustomersTable',

  components: {
    UnlockUser,
    ResetPassword,
    VerifyUser,
    ManageMFA,
    QuickVerifyIcon,
    CallerLogDetails
  },

  props: [
    'customers',
    'customersFound',
    'optionalResultAttrs',
    'settings'
  ],

  computed: {
    headerChoices() {
      return this.headers.filter((header) => {
        return !this.staticHeaders.includes(header.value);
      });
    },
    visibleHeaders() {
      return this.headers.filter((header) => {
        return (
          this.selectedHeaders.includes(header.value) ||
          this.staticHeaders.includes(header.value)
        );
      });
    },
    // helper function to return only the selected headers not the static ones.
    // but keeping the order that he headers are displayed in, the selectedHeaders
    // variable/cookie can change the order of the headers.
    selectableHeaders() {
      return this.headers.filter((header) => {
        return (
          this.selectedHeaders.includes(header.value)
        );
      });
    },
    canManageFactors() {
      // we can manage factors if any reset (single or all), or enroll factors.
      const canManage = !this.settings.featureFlags.disableFactorResetSingle ||
        !this.settings.featureFlags.disableFactorResetAll ||
        this.settings.featureFlags.enableFactorEnrollment;
      return canManage;
    },
    headers() {
      // Create a clone of the base headers, we need to make a deep clone here so that
      // when we translate the headers, we don't change the originals.   This is needed
      // so that the language selector can swap between languages safely.
      let newHeaders = cloneDeep(this.baseHeaders)

      // translate the base headers before returning them, using the current language.
      for (let i = 0; i < newHeaders.length; i++) {
        newHeaders[i].text = $gettext(newHeaders[i].text)
      }

      // Add the optional result attributes (before "Status")
      newHeaders.splice(
        this.optionalRefIndex, 0, ...this.optionalResultAttrs
      );

      // update the optional headers with the translated values for the current language if available.
      for (let i = 0; i < this.optionalResultAttrs.length; i++) {
        let optHeaderIdx = i + this.optionalRefIndex;
        newHeaders[optHeaderIdx].text = newHeaders[optHeaderIdx][this.$parent.$language.current] || newHeaders[optHeaderIdx].text;
      }

      // Add the data table expand header at the end
      if (!this.$appConfig.widget) {
        newHeaders.push({
          'text': '',
          'value': 'data-table-expand'
        });
      }

      return newHeaders;
    }
  },

  data: () => ({

    selectedProfile: {},
    dialogReset: false,
    dialogUnlock: false,
    dialogVerify: false,
    dialogManageMFA: false,
    dialogLogDetails: false,

    // trigger for quickVerify, mapped to the QV property in the Verify component.
    quickVerifyFactorId: null,

    optionalRefIndex: 4,
    items: [],
    eventsLoading: false,
    staticHeaders: [
      'name',
      'status',
      'mfa.status',
      'actions',
      'data-table-expand'
    ],
    selectedHeaders: [
      'profile.email',
      'profile.primaryPhone'
    ],
    // DOC: Base headers are translated right before display so that
    // be updated when the language selector is used to change languages.
    baseHeaders: [
      {
        'text': 'Display name',
        'sortable': true,
        'value': 'name'
      },
      {
        'text': 'Login (username)',
        'sortable': true,
        'value': 'profile.login'
      },
      {
        'text': 'Email',
        'sortable': true,
        'value': 'profile.email'
      },
      {
        'text': 'Primary phone',
        'sortable': true,
        'value': 'profile.primaryPhone'
      },
      {
        'text': 'Status',
        'sortable': false,
        'value': 'status'
      },
      {
        'text': 'MFA',
        'sortable': false,
        'value': 'mfa.status'
      }
    ]
  }),

  watch: {
    selectedHeaders: function (value) {
      if (value) {
        this.$cookies.set('verifySelectedHeaders', value);
      }
    }
  },

  methods: {
    getItemProperty: function (item, header) {
      // assume, the header is in the format <xxxx>.<yyyy>
      const headerElements = header.value.split('.');
      return item[headerElements[0]][headerElements[1]];
    },
    getStatusColor: function (status) {
      // Status passed in here is the status codes provided by our API so
      // these should not be translated or compared against translated values.
      switch (status) {
        case 'ACTIVE':
        case 'ENROLLED':
          return 'success';
        case 'LOADING':
          return "info";
        case 'PENDING':
          return "info";
        default:
          return 'error';
      }
    },
    openActionDialog(dialog, item) {
      this.selectedProfile = item;

      // open the specified action dialog.
      switch (dialog) {
        case 'mfa-verify':
          this.dialogVerify = true;
          break;
        case 'mfa-manage':
          this.dialogManageMFA = true;
          break;
        case 'unlock':
          this.dialogUnlock = true;
          break;
        case 'reset-password':
          this.dialogReset = true;
          break;
        case 'log-details':
          this.dialogLogDetails = true;
          break;
        default:
          break;
      }
    },
    closeActionDialog(dialog) {
      // open the specified action dialog.
      switch (dialog) {
        case 'mfa-verify':
          this.dialogVerify = false;
          break;
        case 'mfa-manage':
          this.dialogManageMFA = false;
          break;
        case 'unlock':
          this.dialogUnlock = false;
          break;
        case 'reset-password':
          this.dialogReset = false;
          break;
        case 'log-details':
          this.dialogLogDetails = false;
          break;
        default:
          break;
      }
    },
    verifySuccess(profile, factor) {
      if (this.$appConfig.verifyCBFun) {
        this.$appConfig.verifyCBFun(profile, factor);
      }
    },
    updateStatus(statusUpdate) {
      this.$emit('updateStatus', statusUpdate);
    },
    getMFAStatusDisplay: function (status) {
      // Lookup the translated display text from the status returned by the API.
      return $gettext(status);
    },
    getQuickVerifyFactorName(factors) {
      // eslint-disable-next-line no-unused-vars
      const qvEnrolled = Object.entries(factors).filter(([key, value]) => {
        return value.status === 'ACTIVE' &&
          `${value.provider}:${value.type}` === this.settings.featureFlags.quickVerifyFactor;
      });

      if (qvEnrolled.length === 0) {
        return $gettext('Unknown');
      }

      // Return the translated version of the Quick Verify factor
      return this.$cvutils.getTranslatedFactorDesc(qvEnrolled[0][1]);
    },
    loadEvents: function (expanded) {
      // Exit early for collapsing items
      if (!expanded.value) {
        return;
      }

      // Exit early for events already loaded
      if (expanded.item.eventsLoaded) {
        // console.log(`Events already loaded for ${expanded.item.id}`);
        return;
      }

      // Start the loading indicator
      this.eventsLoading = true;

      // console.log(`Loading events for ${expanded.item.id}`);
      this.$ajax.get(`/api/v1/logs/${expanded.item.id}`).then(response => {
        if (response.status == 200 && response.data) {
          const data = response.data;
          if (data.status) {
            expanded.item.events = data.events;
            expanded.item.eventsLoaded = true;
          } else {
            // Emit a notification with the error message
            this.showNotification({
              title: $gettext('Error: Loading Events'),
              body: $gettext('An error occurred while loading failure events.'),
              type: 'error',
              timeout: -1
            });
          }
        }
      }).catch(err => {
        console.error(err);

        // Emit a notification with the error message
        this.showNotification({
          title: $gettext('Error: Loading Events'),
          body: $gettext('An error occurred while loading failure events.'),
          type: 'error',
          timeout: -1
        });
      }).finally(() => {
        // Stop the loading indicator
        this.eventsLoading = false;
      });
    },
    showNotification: function (notification) {
      this.$emit('showNotification', notification);
    },
    headersContain(headers, header) {
      // Return true if "headers" contain the "header" object
      headers.forEach(element => {
        if (element.value === header.value) {
          return true;
        }
      });
    },
    isQuickVerify(item) {
      // No Quick Verify factor configured, disabled Quick Verify button
      if (this.settings.featureFlags.quickVerifyFactor === null || this.settings.featureFlags.quickVerifyFactor === '') {
        return false;
      }

      // Iterate over enrolled factors for configured Quick Verify factor
      const mfa = item.mfa;
      // eslint-disable-next-line no-unused-vars
      const qvEnrolled = Object.entries(mfa.factors).filter(([key, value]) => {
        return value.status === 'ACTIVE' &&
          `${value.provider}:${value.type}` === this.settings.featureFlags.quickVerifyFactor;
      });

      if (item.status === 'ACTIVE' && mfa.status === 'ENROLLED' && qvEnrolled.length > 0) {
        return true;
      }

      return false;
    },
    quickVerify(item) {
      this.selectedProfile = item;
      // Retrieve the factor Id for the Quick Verify factor
      const factors = this.selectedProfile.mfa.factors;
      // eslint-disable-next-line no-unused-vars
      const qvEnrolled = Object.entries(factors).filter(([key, value]) => {
        return value.status === 'ACTIVE' &&
          `${value.provider}:${value.type}` === this.settings.featureFlags.quickVerifyFactor;
      });

      if (qvEnrolled.length === 0) {
        // Show notification for Quick Verify factor not found
        this.showNotification({
          title: $gettext('MFA Verification Error'),
          body: $gettext('A Quick Verify factor does not appear to be enrolled.'),
          type: 'warning',
          timeout: 5000
        });

        return false;
      }
      // trigger watch event in <Verify> component via property mapping.
      this.quickVerifyFactorId = qvEnrolled[0][1].id;
    }
  },

  created() {
    // Load (pivot) customers object into "items" array
    this.items = [];

    Object.keys(this.customers).forEach(userid => {
      this.items.push(this.customers[userid]);
    });

    // Only add the "Action" column for write access
    if (this.settings.permission === 'read-write') {
      this.baseHeaders.push({
        'text': $gettext('Action(s)'),
        'sortable': false,
        'value': 'actions'
      });
    }

    // Add the optional result attributes to the selected attributes list
    if (this.settings.defaultDisplayHeaders) {
      this.selectedHeaders = [];
      this.settings.defaultDisplayHeaders.map(attr => {
        this.selectedHeaders.push(attr);
      });
    }

    // Override the selected headers from cookie (if available)
    if (this.$cookies.isKey('verifySelectedHeaders')) {
      const value = this.$cookies.get('verifySelectedHeaders');
      // value is non-empty.
      if (value) {
        // if the value is already an array we are done.
        if(Array.isArray(value)) {
          this.selectedHeaders = value;
        }
        // otherwise assume its just comma separated and convert  it into an array.
        else {
          this.selectedHeaders = value.split(',');
        }
      }
      // empty value but cookie exists == no custom headers.
      else {
        this.selectedHeaders = [];
      }
    }

    if (this.$appConfig.widget) {
      this.selectedHeaders = [];
    }
  }
}
</script>

<style scoped>
.narrow-select {
  max-width: 400px;
}

.v-icon.expanded {
  transform: rotate(180deg);
}
</style>
